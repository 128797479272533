<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {getTokenDemo} from "./api/index";
import { Notify } from 'vant';
export default{
    name: 'App',
    created() {
        // this.getToken()
    },
    methods:{
        // 测试
        getToken(){
            let para ={
                url: String(location.href.split('#')[0])

            }

            getTokenDemo(para).then(res => {
                // alert(JSON.stringify(res)+navigator.userAgent)
                if (res.code === 200) {
                    wx.config({
                        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: 'ww0ade117fa232c373', // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            "hideOptionMenu",
                            'hideAllNonBaseMenuItem',
                        ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                    //配置成功以后config:ok
                    wx.ready(function (res) {
                        //隐藏右上角菜单接口
                        wx.hideAllNonBaseMenuItem();
                        wx.hideOptionMenu();
                    })

                    wx.error(function(res){
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
                } else {

                }
            }).catch((err) => {
                // this.$message.error('服务器连接失败200!')
                Notify({ type: 'warning', message: err + '0419' });
            })
        },
    }
}

</script>

<style lang="scss">
html,
body,#app {
  margin: 0;
  padding: 0;
  /*height: 100%;*/
  background: #F5F5FC;
}

</style>
